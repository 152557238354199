import axios from 'axios'
import {notify} from "../components/Notify";
import {nodeFromString} from "../tools/NodeFromString";

export default function eventPeriods() {
    let container = document.querySelector('.order-bouquets-container')

    if (container) {
        let list = container.querySelector('.order-bouquets'),
            count = container.querySelectorAll('.order-bouquet').length

        function initItem(item) {
            item.querySelector('.order-bouquet-remove')?.addEventListener('click', e => {
                e.preventDefault()
                item.remove()
                resolveOrderPrice()
            })
            item.querySelector('.select-bouquet-size')?.addEventListener('change', e => {
                item.querySelector('.select-bouquet-price').value = e.target.selectedOptions[0].dataset.price
                resolveOrderPrice()
            })
            item.querySelector('.select-bouquet-count')?.addEventListener('change', e => {
                resolveOrderPrice()
            })
            item.querySelector('.select-bouquet')?.addEventListener('change', e => {
                e.preventDefault()
                console.log(e.target.value)
                if (e.target.value.length > 0) {
                    axios.get(`/admin/orders/bouquet-sizes/` + e.target.value)
                        .then(result => {
                            let items = []
                            let selector = item.querySelector('.select-bouquet-size')
                            let disabledInput = item.querySelector('.select-bouquet-price')
                            for (let bouquetSizeId in result.data.data) {
                                let price = result.data.data[bouquetSizeId].price
                                let element = "<option value='" + bouquetSizeId + "' " + (items.length > 0 ? "" : "selected") + " data-price='" + price + "'>" + result.data.data[bouquetSizeId].title + "</option>";
                                if (items.length === 0) {
                                    disabledInput.value = price
                                }
                                items.push(element);
                            }
                            selector.innerHTML = items.join()
                            resolveOrderPrice()
                        })
                        .catch(error => {
                            notify('Error', 'danger', error)
                        })
                } else {
                    item.querySelector('.select-bouquet-size').innerHTML = ''
                }
            })
        }
        /** Перерасчет итоговой стоимости букета */
        function resolveOrderPrice() {
            let bouquets = container.querySelectorAll('.order-bouquet')
            let orderPrice = 0
            Array.from(bouquets).forEach(item => {
                let price = item.querySelector('.select-bouquet-price').value
                let count = item.querySelector('.select-bouquet-count').value
                item.querySelector('.select-bouquet-result').innerHTML = (parseFloat(price) * parseInt(count)).toString()
                orderPrice += parseFloat(price) * parseInt(count);
            })
            let orderPriceElement = document.getElementById("order-price")
            orderPriceElement.innerHTML = orderPrice.toString()
        }
        function initAdd() {
            container.querySelector('.order-bouquets-add')?.addEventListener('click', e => {
                e.preventDefault()
                axios.get(`/admin/orders/bouquet-size?index=${++count}`)
                    .then(result => {
                        let item = nodeFromString(result.data.data)
                        initItem(item)
                        list.append(item)
                    })
                    .catch(error => {
                        notify('Error', 'danger', error)
                    })
            })
        }

        initAdd()
        container.querySelectorAll('.order-bouquet').forEach(initItem)
    }
}

